import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ACTIVITY_LOGS_DATA_TYPE_CUSTOM_EVENT } from 'Consts/activityLogs';
import { ADMIN_CUSTOM_EVENTS, ADMIN_CUSTOM_EVENT_USPS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/customEvents/Editor';
import LogsList from 'Components/admin/activityLogs/List';
import CustomEventUspsList from 'Components/admin/customEventUsps/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';
export const TAB_USPS = 'usps';
export const TAB_POP_UPS = 'popUps';

export default class AdminCustomEventManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        customEvent: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, customEvent } = this.props;
        const { search } = location;

        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-custom-event-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={customEvent}
                >
                    {customEvent && customEvent.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_CUSTOM_EVENTS.path),
                                label: 'Lista wydarzeń',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj wydarzenie"
                                controls={[{
                                    visible: Boolean(queryObject.tab === 'usps'),
                                    key: 'createUsp',
                                    label: 'Nowe USP',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_CUSTOM_EVENT_USPS_CREATE.path,
                                            {},
                                            { customEventId: customEvent.data.id }
                                        )
                                    ),
                                }]}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={customEvent.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_USPS,
                                    label: 'USPs',
                                    children: (
                                        <CustomEventUspsList
                                            location={location}
                                            history={history}
                                            data={customEvent.data}
                                            predefinedQuery={{
                                                customEventId: customEvent.data.id,
                                            }}
                                        />
                                    )
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_CUSTOM_EVENT,
                                                dataId: customEvent.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}