import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/customEventUsps/actions';

export default connect(
    state => ({
        customEventUsps: state.adminCustomEventUsps.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);
